import $ from 'jquery'
import _ from 'lodash'
require('jquery-ui');
require('../../js/plugins/jquery-timepicker');
import { DateTime, Duration } from 'luxon'

$( () => {
    new editTachoDay($('#form table#edit_day'));
});

class editTachoDay {

    _debug = false
    $table
    $activityBody
    $btnAdd
    $btnDelete
    $timePicker
    $activityPicker
    $datePicker
    trTemplate
    currentState
    initialState

    constructor($table)
    {
        const $templateAddSelect = app.DOM.content.find('select#template-add');
        const $resetButton = app.DOM.content.find('#btn-reset');

        this.storeTemplateHtml();

        this.initialState = JSON.parse($('#event_doc').val());

        // pass by value not by reference hence parse/stringify
        this.init($table, JSON.parse(JSON.stringify(this.initialState)));

        // insert template dropdown
        $templateAddSelect.on('change', e => {
            this.insertTemplateRows(e);
        });

        // reset view button
        $resetButton.on('click', e => {
            $templateAddSelect.find('option').eq(0).prop('selected', true);
            $templateAddSelect.trigger('change');
        });
    }

    init($table, json)
    {
        this.$table = $table;
        this.$activityBody = $table.find('tbody#activity_container');

        this.displayActivities(json);
        this.cacheElements();
        this.refreshDurations()
        this.events();
    }

    storeTemplateHtml()
    {
        this.trTemplate = $('tr.template')[0].outerHTML;
    }

    cacheElements()
    {
        this.$btnAdd = this.$activityBody.find('.btn-add');
        this.$btnDelete = this.$activityBody.find('.btn-delete');
        this.$timePicker = this.$activityBody.find('input.timepicker[readonly!=readonly]');
        this.$activityPicker = this.$activityBody.find('select.activitypicker[readonly!=readonly]')
        this.$datePicker = this.$activityBody.find('input.datepicker[readonly!=readonly]')
    }

    events()
    {
        this.$btnAdd.unbind().on('click', e => this.addButtonEvent(e));
        this.$btnDelete.unbind().on('click', e => this.deleteButtonEvent(e));
        this.$timePicker.timepicker({
            'scrollDefault': '00:01',
            'timeFormat': 'H:i',
            'step': 1,
            'minTime': '00:01',
            'maxTime': '23:59',
        }).on('changeTime', e => {
            this.updateStore();
        })

        this.$datePicker.datepicker({
            dateFormat: 'dd-mm-yy',
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true,
            position: 'top',
            onSelect: (dateText) => {
                this.value = dateText
                this.updateStore()
            }
        });

        this.$activityPicker.on('change', e => {
            this.updateStore();
        });

        // if (this._debug) {
        //     $('textarea#event_doc').change((e) => {
        //         const json = JSON.parse(e.currentTarget.value)
        //         this.displayActivities(json);
        //         this.cacheElements();
        //         this.refreshDurations()
        //
        //         this.events();
        //     }).removeClass('hidden')
        // }
    }

    insertTemplateRows(e)
    {
        const templateType = e.currentTarget.value;

        if( ['half', 'full'].includes(templateType) ){
            this.init(this.$table, app.OPTIONS.template_data[templateType]);
        } else {
            this.init(this.$table, this.initialState);
        }

        this.updateStore();
    }

    displayActivities(json)
    {
        this.currentState = json;

        this.$activityBody.empty();

        const format = {...DateTime.DATE_MED_WITH_WEEKDAY, ...DateTime.TIME_24_SIMPLE};
        const prev = DateTime.fromISO(this.currentState.prev);
        const next = DateTime.fromISO(this.currentState.next);

        this.$table.find('#prev_rest').text(prev.isValid ? prev.toLocaleString(format) : 'None')
        this.$table.find('#next_activity').text(next.isValid ? next.toLocaleString(format) : 'None')

        for(const i in this.currentState.activities) {
            const {activity, date, time, tachograph} = this.currentState.activities[i];
            this.addTableRow(this.$activityBody, i, tachograph, activity, date, time);
        }

        const lastActivity = this.currentState.activities[this.currentState.activities.length - 1]
        // if (!lastActivity || (lastActivity.time !== '23:59' && lastActivity.activity !== 0 && lastActivity.activity !== 4)) {
            this.addTableRow(
              this.$activityBody,
              this.currentState.activities.length,
              false,
              4,
              app.URI[3],
              this.currentState.activities.length === 0 ? '00:00' : '23:59'
            )
        // }
    }

    addTableRow(tag, index, tachograph, activity, date, time) {

        let html = this.trTemplate;
        html = html.replaceAll(/#i#/g, index);
        const $html = $(html);
        $html.attr('id', index)
        $html.removeClass('template')
        const activityPicker = $html.find('select.activitypicker');
        const datepicker = $html.find('input.datepicker');
        const timepicker = $html.find('input.timepicker');

        if( !date ) {
            date = app.URI[3];
        }

        activityPicker.val(activity);
        datepicker.val(date);
        timepicker.val(time);

        if (tachograph) {
            activityPicker.prop('readonly', true).prop('disabled', true)
            datepicker.attr('readonly', true)
            timepicker.attr('readonly', true)
            $html.find('td.col_ctrl a').addClass('hidden')
        }

        if (tag[0].tagName === 'TBODY') {
            tag.append($html)
        } else {
            tag.after($html)
        }
    }

    addButtonEvent(e)
    {
        // get tr
        const $tr = $(e.currentTarget).closest('tr');
        this.addTableRow($tr, 0, false, 4)

        this.reindexRows()
        this.updateStore()

        this.cacheElements();
        this.events();
    }

    deleteButtonEvent(e)
    {
        if( this.$activityBody.find('tr').length === 1 ){
            this.updateStore()
            return;
        }

        $(e.currentTarget).closest('tr').remove();
        this.updateStore()
    }

    reindexRows()
    {
        this.$activityBody.find('tr').each((i, e) => {
            const $e = $(e);
            const index = $e.prop('id')
            $e.data('id', index);

            const activityPicker = $e.find('select.activitypicker')
            const datepicker = $e.find('input.datepicker')
            const timepicker = $e.find('input.timepicker')
            const duration = $e.find('span.duration')

            activityPicker.prop('id', 'activity_' + index)
            datepicker.prop('id', 'datepicker_' + index)
            timepicker.prop('id', 'timepicker_' + index)
            duration.prop('id', 'duration_' + index)

            duration.text('-')
        })
    }

    updateStore ()
    {
        this.$activityBody.find('tr').each((index, e) => {
            const $e = $(e);
            $e.data('id', index);

            const activityPicker = $e.find('select.activitypicker')
            const datepicker = $e.find('input.datepicker')
            const timepicker = $e.find('input.timepicker')
            const duration = $e.find('span.duration')

            let currentIndex = $e.attr('id')
            const dateIndex = DateTime.fromFormat(`${datepicker.val()} ${timepicker.val()}`, 'dd-MM-yyyy HH:mm', {
                zone: 'UTC'
            }).toFormat('yyyyMMddHHmmss');
            if (!currentIndex) {
                currentIndex = dateIndex
                $e.attr('id', dateIndex);
            }
            let row = this.currentState.activities[currentIndex];

            if (row === undefined) {
                row = {tachograph: false}
            }

            row.activity = activityPicker.val()
            row.date = datepicker.val()
            row.time = timepicker.val()

            if (!this.currentState.activities[currentIndex]) {
                this.currentState.activities[dateIndex] = row;
                $e.attr('id', dateIndex);
            }

            if (currentIndex !== dateIndex) {
                delete this.currentState.activities[currentIndex];
                this.currentState.activities[dateIndex] = row;
                $e.attr('id', dateIndex);
            }

            duration.text('-')
        })

        $('textarea#event_doc').val(JSON.stringify(this.currentState))
        this.refreshTable()
        this.refreshDurations()
    }

    refreshTable()
    {
        const rows = {};
        this.$activityBody.hide();
        this.$activityBody.find('tr').each((i, e) => {
            const $e = $(e);
            rows[$e.attr('id')] = $e
            $e.detach()
        })

        Object.keys(rows).toSorted().forEach((row) => {
            this.$activityBody.append(rows[row])
        })

        this.reindexRows()
        this.$activityBody.show();
    }

    refreshDurations()
    {
        this.activities = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
        };

        let negativeTimesExist = false;

        const arr = Object.keys(this.currentState.activities).sort()
        for (const [index, data] of Object.entries(this.currentState.activities)) {
            const rowIndex = _.indexOf(arr, index)
            if (rowIndex === 0) {
                // First event - duration should be to previous entry. If the time is midnight, run the duration to the
                // next event
                const start = DateTime.fromISO(this.currentState.prev).toUTC();
                const endRow = this.currentState.activities[arr[rowIndex + 1]];
                const end = DateTime.fromFormat(`${endRow.date} ${endRow.time}`, 'dd-MM-yyyy HH:mm', {
                    zone: 'UTC'
                });
                const duration = end.diff(start);
                this.$activityBody.find(`span#duration_${index}`).text(duration.toFormat("h'h' mm'm'"));

                continue;
            }

            if (rowIndex < arr.length - 1) {
                const start = DateTime.fromFormat(`${data.date} ${data.time}`, 'dd-MM-yyyy HH:mm', {
                    zone: 'UTC'
                });

                if (!start.isValid) {
                    continue;
                }

                const endRow = this.currentState.activities[arr[rowIndex + 1]];
                const end = DateTime.fromFormat(`${endRow.date} ${endRow.time}`, 'dd-MM-yyyy HH:mm', {
                    zone: 'UTC'
                });

                if (!end.isValid) {
                    continue;
                }

                const duration = end.diff(start);
                const css = ( duration.milliseconds <= 0 ) ? 'duration-negative' : 'duration-positive';

                if( css === 'duration-negative' ) {
                    negativeTimesExist = true;
                }

                this.$activityBody.find(`span#duration_${index}`)
                  .removeClass('duration-positive duration-negative')
                  .addClass(css)
                  .text(duration.toFormat("h'h' mm'm'"));
                this.activities[data.activity]+=duration.as('milliseconds');
            }

            if (rowIndex === arr.length - 1) {
                // Handle last event of day - duration to next known non-midnight event, but don't increment the counters
                const start = DateTime.fromFormat(`${data.date} ${data.time}`, 'dd-MM-yyyy HH:mm', {
                    zone: 'UTC'
                });
                const end = DateTime.fromISO(this.currentState.next).toUTC();

                const duration = end.diff(start);
                this.$activityBody.find(`span#duration_${index}`).text(duration.toFormat("h'h' mm'm'"));
            }
        }

        $('span#total_driving').text(Duration.fromMillis(this.activities[3]).toFormat("h'h' mm'm'"));
        $('span#total_working').text(Duration.fromMillis(this.activities[2]).toFormat("h'h' mm'm'"));
        $('span#total_available').text(Duration.fromMillis(this.activities[1]).toFormat("h'h' mm'm'"));
        $('span#total_rest').text(Duration.fromMillis(this.activities[0]).toFormat("h'h' mm'm'"));


        if( negativeTimesExist ) {
            app.FORM.disable_save(true, 'You cannot save manual entry, please fix duration lengths to be in correct order');
        } else {
            app.FORM.disable_save(false);
        }
    }
}